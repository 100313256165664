import * as React from "react";

function SvgMyTeam(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 9a5 5 0 015 5v6h-2v-6a3 3 0 00-2.824-2.995L10 11a3 3 0 00-2.995 2.824L7 14v6H5v-6a5 5 0 015-5zm-6.5 3c.279 0 .55.033.81.094a5.947 5.947 0 00-.301 1.575L4 14v.086a1.494 1.494 0 00-.356-.08L3.5 14a1.5 1.5 0 00-1.493 1.356L2 15.5V20H0v-4.5A3.5 3.5 0 013.5 12zm13 0a3.5 3.5 0 013.5 3.5V20h-2v-4.5a1.5 1.5 0 00-1.356-1.493L16.5 14c-.175 0-.343.03-.5.085V14c0-.666-.108-1.306-.309-1.904A3.42 3.42 0 0116.5 12zm-13-6a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm13 0a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm-13 2a.5.5 0 100 1 .5.5 0 000-1zm13 0a.5.5 0 100 1 .5.5 0 000-1zM10 0a4 4 0 110 8 4 4 0 010-8zm0 2a2 2 0 100 4 2 2 0 000-4z"
        fill="currentColor"
        fillOpacity={0.6}
      />
    </svg>
  );
}

export default SvgMyTeam;
